import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Form,
  Stack,
  Button,
  Offcanvas,
  Table,
  Modal,
  FormCheck,
  Alert
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

import { getGamesApi } from "../apis/coupon";
import { store } from "../store";
import toast from "react-hot-toast";
import { customToastStyle } from "../configs/constants";

export default function GameTable() {
  const nav = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [sports, setSports] = useState("");
  const [coming, setComing] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const [winningCoupons, setWinningCoupons]: [
    selectedCoupons: any,
    setSelectedCoupons: Function
  ] = useState([]);
  const [showParlayModal, setShowParlayModal] = useState(false);
  const [parlayList, setParlayList] = useState([]);
  const [userInfo, setUserInfo] = store.useState("userInfo");
  const myCouponIds: any = (userInfo as any)?.coupons || {};

  const viewTableData = useMemo(() => {
    if (sports === "") return tableData;
    return tableData?.filter((item: any) => item?.sports === sports);
  }, [tableData, sports]);

  const filteredTableData = useMemo(() => {
    switch (coming) {
      case "Upcoming":
        return viewTableData?.filter(
          (item: any) =>
            new Date(item.startDate + " " + item.startTime) >= new Date()
        );
      case "Finished":
        return viewTableData?.filter(
          (item: any) =>
            new Date(item.startDate + " " + item.startTime) < new Date()
        );
      default:
        return viewTableData;
    }
  }, [viewTableData, coming]);

  const parlayInfoText = (num: number) => {
    let str = ""
    let score = 1
    for (let i = 0; i < num; i++) {
      str += `${i + 1} * `
      score = score * (i + 1);
    }
    str = str.substr(0, str.length - 2);
    if (num >= 4) {
      str += " + 10% Bonus";
      score = Math.floor(score * 1.1 * 100) / 100;
    }
    return `${str} = ${score}(X)`
  }

  const parlayDisabled = (row: any) => {
    if (parlayList[0] && row.gameType !== "Coupon") {
      if ((parlayList[0] as any)?.sports === row.sports) {
        return false;
      }
      return true
    }
    return false;
  }

  const nextHandler = () => {
    nav("/bet-parlay/diamond", { state: parlayList });
  }

  const columns = [
    {
      name: "Sports",
      selector: (row: any) => row.sports === "Football" ? "Futbol" : row.sports,
      sortable: true,
    },
    {
      name: "Home Team",
      selector: (row: any) => row.home,
      sortable: true,
    },
    {
      name: "Away Team",
      selector: (row: any) => row.away,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row: any) => row.startDate + " " + row.startTime,
      sortable: true,
    },
    {
      name: "Game Status",
      selector: (row: any) =>
        new Date(row.startDate + " " + row.startTime) >= new Date()
          ? "Upcoming"
          : "Finished",
      sortable: true,
    },
    {
      name: "Winning Coupons",
      selector: (row: any) => {
        let coupons: any = [];
        try {
          coupons = JSON.parse(row.coupons);
        } catch (e) { }
        if (coupons.length > 0) {
          return (
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => {
                setShowCoupon(true);
                setWinningCoupons(coupons);
              }}
            >
              Win Coupons
            </Button>
          );
        }
        return null;
      },
      sortable: true,
    },
    {
      name: "Game Type",
      selector: (row: any) => row.gameType,
      sortable: true,
    },
    {
      name: "Bet",
      selector: (row: any) =>
        new Date(row.startDate + " " + row.startTime) >= new Date() ? (
          <Button
            size="sm"
            variant="success"
            disabled={parlayList.length > 0}
            onClick={() => {
              const couponIds = Object.keys(myCouponIds) || [];
              if (couponIds.length === 0 && row.gameType === "Coupon") {
                return toast("Please buy coupons to bet", customToastStyle)
              }
              nav("/bet/diamond", { state: row });
            }}
          >
            Bet
          </Button>
        ) : null,
      sortable: false,
    },
    {
      name: "Parlay Bet",
      selector: (row: any) =>
        new Date(row.startDate + " " + row.startTime) >= new Date() ? (
          <FormCheck label={`Parlay Bet`} checked={parlayList.findIndex((item: any) => { return item.id === row.id }) !== -1} disabled={parlayDisabled(row)} onChange={(e: any) => {
            const _index = parlayList.findIndex((item: any) => { return item.id === row.id });
            if (_index === -1) {
              setParlayList([...parlayList, row as never])
            } else {
              setParlayList((prev) => {
                return prev.filter((item: any) => {
                  return item.id !== row.id
                });
              })
            }

          }} />
        ) : null,
      sortable: false,
    },
  ];

  useEffect(() => {
    (async () => {
      setTableData(await getGamesApi());
    })();
  }, []);

  return (
    <Container>
      <Offcanvas show={showCoupon} onHide={() => setShowCoupon(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Winning Coupons</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Coupon Type</th>
                <th>Coupon Value</th>
                <th>Payout</th>
              </tr>
            </thead>
            <tbody>
              {winningCoupons.map((coupon: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{coupon.couponType}</td>
                    <td>{coupon.couponValue}</td>
                    <td>{coupon.payout}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showParlayModal} centered onHide={() => setShowParlayModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 18, fontWeight: "bold" }}>
            Parlay Bet ({`${(parlayList[0] as any)?.sports === "Football" ? "Futbol" : (parlayList[0] as any)?.sports}`})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="info">
            Estimated Calculation Score = {parlayInfoText(parlayList.length)}
          </Alert>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Sports</th>
                <th>Home Team</th>
                <th>Away Team</th>
                <th>Start Date</th>
                <th>Game Type</th>
              </tr>
            </thead>
            <tbody>
              {
                parlayList.map((item: any, index: number) => {
                  return (
                    <tr key={uuidv4()}>
                      <td>{index + 1}</td>
                      <td>{item?.sports === "Football" ? "Futbol" : item?.sports}</td>
                      <td>{item?.home}</td>
                      <td>{item?.away}</td>
                      <td>{item.startDate + " " + item.startTime}</td>
                      <td>{item?.gameType}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={nextHandler}>
            Go
          </Button>
        </Modal.Footer>
      </Modal>


      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <h1>Games List</h1>
        <Stack direction="horizontal">
          <Form.Select
            aria-label="Sports"
            onChange={(e) => {
              setSports(e.target.value);
            }}
            style={{ maxWidth: 150 }}
            defaultValue={sports}
          >
            <option value="">All</option>
            <option value="Football">Futbol</option>
            <option value="NBA Basketball">NBA Basketball</option>
            <option value="Other Basketball">Other Basketball</option>
            <option value="Cricket">Cricket</option>
          </Form.Select>
          <Form.Select
            aria-label="Sports"
            onChange={(e) => {
              setComing(e.target.value);
            }}
            style={{ maxWidth: 150, marginLeft: 10 }}
            defaultValue={coming}
          >
            <option value="">All</option>
            <option value="Upcoming">Upcoming</option>
            <option value="Finished">Finished</option>
          </Form.Select>

          {
            parlayList.length > 0 &&
            <Button
              style={{ width: 150, height: 41, marginLeft: 10 }}
              size="sm"
              variant="primary"
              onClick={() => {
                setShowParlayModal(true)
              }}
            >
              Parlay Bet
            </Button>
          }
        </Stack>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={filteredTableData} pagination />
      </Stack>
    </Container>
  );
}
