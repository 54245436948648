import { useState, useEffect, useMemo } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  ListGroup,
  Button,
  Stack,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import Layout from "../layout";
import Header from "../components/Header";
import { customToastStyle } from "../configs/constants";

import {
  betGameDiamondApi,
  betGameCouponApi,
  getBetListDiamondApi,
  getBetListCouponApi,
} from "../apis/coupon";

import { store } from "../store";

export default function BetDiamond() {
  const nav = useNavigate();

  const { state: selectedGame } = useLocation();

  const [key, setKey]: [key: any, setKey: Function] = useState("diamond");

  const [betCouponType, setBetCouponType] = useState("");
  const [myBetCouponType, setMyBetCouponType] = useState("");
  const [betCouponValue, setBetCouponValue] = useState("");
  const [myBetCouponValue, setMyBetCouponValue] = useState("");
  const [betDiamondAmount, setBetDiamondAmount] = useState("");
  const [diamondBetList, setDiamondBetList]: [
    diamondBetList: any,
    setDiamondBetList: Function
  ] = useState([]);
  const [couponBetList, setCouponBetList]: [
    couponBetList: any,
    setCouponBetList: Function
  ] = useState([]);

  const [coupons] = store.useState("coupons");
  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");
  const diamondBalance = (userInfo as any)?.diamond;
  const myCouponIds: any = (userInfo as any)?.coupons || {};

  const filteredCoupons = (coupons as any)?.filter(
    (item: any) => item?.Sport === selectedGame?.sports
  );

  const myCoupons = useMemo(() => {
    const couponIds = Object.keys(myCouponIds) || [];
    const result = [];
    let resultIndex = 0;
    for (const couponId of couponIds) {
      const index = (coupons as any).findIndex(
        (coupon: any) => coupon.id === couponId
      );
      const _coupon = (coupons as any)[index];
      result.push({
        ..._coupon,
        hold: myCouponIds[couponId],
        index: resultIndex,
      });
      resultIndex++;
    }
    return result;
  }, [userInfo, coupons, myCouponIds]);

  const filteredMyCoupons = (myCoupons as any)?.filter(
    (item: any) => item?.Sport === selectedGame?.sports
  );

  const couponTypeList = useMemo(() => {
    const data: any = {};
    for (const coupon of filteredCoupons) {
      if (data[coupon["Coupon Type"]] === undefined) {
        data[coupon["Coupon Type"]] = "";
      }
    }
    return Object.keys(data);
  }, [filteredCoupons]);

  const myCouponTypeList = useMemo(() => {
    const data: any = {};
    for (const coupon of filteredMyCoupons) {
      if (data[coupon["Coupon Type"]] === undefined) {
        data[coupon["Coupon Type"]] = "";
      }
    }
    return Object.keys(data);
  }, [filteredMyCoupons]);

  const couponValueList = useMemo(() => {
    try {
      if (betCouponType === "") {
        return [];
      }
      const filteredByType = filteredCoupons?.filter((item: any) => {
        return item["Coupon Type"] === betCouponType;
      });

      const data: any = {};
      for (const coupon of filteredByType) {
        if (data[coupon["Value"]] === undefined) {
          data[coupon["Value"]] = "";
        }
      }
      return Object.keys(data);
    } catch (e) {
      return [];
    }
  }, [filteredCoupons, couponTypeList]);

  const myCouponValueList = useMemo(() => {
    try {
      if (myBetCouponType === "") {
        return [];
      }
      const myFilteredByType = filteredMyCoupons?.filter((item: any) => {
        return item["Coupon Type"] === myBetCouponType;
      });

      const data: any = {};
      for (const coupon of myFilteredByType) {
        if (data[coupon["Value"]] === undefined) {
          data[coupon["Value"]] = "";
        }
      }
      return Object.keys(data);
    } catch (e) {
      return [];
    }
  }, [filteredMyCoupons]);

  useEffect(() => {
    (async () => {
      setDiamondBetList(
        await getBetListDiamondApi({
          gameId: selectedGame.id,
          wallet: account,
        })
      );

      setCouponBetList(
        await getBetListCouponApi({
          gameId: selectedGame.id,
          wallet: account,
        })
      );
    })();
  }, []);

  const betDiamondHandler = async () => {
    if (betCouponType === "") {
      return toast("Select a coupon type", customToastStyle);
    }
    if (betCouponValue === "") {
      return toast("Select a coupon value", customToastStyle);
    }
    if (isNaN(parseFloat(betDiamondAmount))) {
      return toast("Please input diamond amount", customToastStyle);
    }
    if (parseFloat(diamondBalance) < parseFloat(betDiamondAmount)) {
      return toast("Diamond balance error", customToastStyle);
    }

    const selectedCoupon = (coupons as any)?.filter(
      (item: any) =>
        item?.Sport === selectedGame?.sports &&
        item["Coupon Type"] === betCouponType &&
        item["Value"] === betCouponValue
    );

    const res = await betGameDiamondApi({
      gameId: selectedGame.id,
      wallet: account,
      couponType: betCouponType,
      couponValue: betCouponValue,
      diamondAmount: betDiamondAmount,
      couponId: selectedCoupon[0].id,
    });

    if (res === true) {
      toast("Bet game with diamond successfully", customToastStyle);
      setUserInfo({
        ...(userInfo as any),
        diamond:
          parseFloat((userInfo as any).diamond) - parseFloat(betDiamondAmount),
      });
    } else {
      return toast("Betting error", customToastStyle);
    }

    setDiamondBetList([
      ...diamondBetList,
      {
        couponType: betCouponType,
        couponValue: betCouponValue,
        diamondAmount: betDiamondAmount,
      },
    ]);

    setBetCouponType("");
    setBetCouponValue("");
    setBetDiamondAmount("");
  };

  const betCouponHandler = async () => {
    if (myBetCouponType === "") {
      return toast("Select a coupon type", customToastStyle);
    }
    if (myBetCouponValue === "") {
      return toast("Select a coupon value", customToastStyle);
    }

    const selectedCoupon = (coupons as any)?.filter(
      (item: any) =>
        item?.Sport === selectedGame?.sports &&
        item["Coupon Type"] === myBetCouponType &&
        item["Value"] === myBetCouponValue
    );

    const res = await betGameCouponApi({
      gameId: selectedGame.id,
      wallet: account,
      couponType: myBetCouponType,
      couponValue: myBetCouponValue,
      couponId: selectedCoupon[0].id,
    });

    if (res === true) {
      toast("Bet game with coupon successfully", customToastStyle);

      let _myCouponIds = { ...myCouponIds };
      if (
        _myCouponIds[selectedCoupon[0].id] &&
        _myCouponIds[selectedCoupon[0].id] > 0
      ) {
        _myCouponIds[selectedCoupon[0].id]--;
      }
      if (_myCouponIds[selectedCoupon[0].id] === 0) {
        delete _myCouponIds[selectedCoupon[0].id];
      }

      setUserInfo({
        ...(userInfo as any),
        coupons: _myCouponIds,
      });
    } else {
      return toast("Betting error", customToastStyle);
    }

    setCouponBetList([
      ...couponBetList,
      {
        couponType: myBetCouponType,
        couponValue: myBetCouponValue,
      },
    ]);

    setMyBetCouponType("");
    setMyBetCouponValue("");
  };

  return (
    <Layout>
      <div className="page-container">
        <div style={{ zIndex: 2 }} className="w-100">
          <Header />
          <Container style={{ marginTop: 50 }}>
            <Form className="w-100">
              <Stack
                direction="horizontal"
                style={{ alignItems: "center", display: "flex" }}
              >
                <h2>Bet Diamond</h2>
              </Stack>

              <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                {(selectedGame.gameType === "Coupon" ||
                  selectedGame.gameType === "Diamond/Coupon") && (
                  <Tab eventKey="coupon" title="Bet Coupon">
                    {myCouponTypeList.length > 0 && (
                      <Row>
                        <Col>
                          <Form.Label>Coupon Type</Form.Label>
                        </Col>
                        <Col>
                          <Form.Label>Coupon Value</Form.Label>
                        </Col>
                      </Row>
                    )}
                    {myCouponTypeList.length > 0 && (
                      <Row>
                        <Col>
                          <ListGroup>
                            {myCouponTypeList.map((item: any) => (
                              <ListGroup.Item
                                key={uuidv4()}
                                onClick={() => {
                                  setMyBetCouponType(item);
                                  setMyBetCouponValue("");
                                }}
                                active={item === myBetCouponType}
                                style={{ cursor: "pointer" }}
                              >
                                {item}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </Col>
                        <Col>
                          <Form.Select
                            aria-label="Coupon"
                            value={myBetCouponValue}
                            onChange={(e) => {
                              setMyBetCouponValue(e.target.value);
                            }}
                          >
                            <option value="">Please select option</option>
                            {myCouponValueList.map((item: any) => (
                              <option key={uuidv4()}>{item}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                    )}
                    {myCouponTypeList.length > 0 && (
                      <Row
                        className="m-2 mt-4"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button size="sm" onClick={betCouponHandler}>
                          Bet Coupon
                        </Button>
                      </Row>
                    )}
                    {couponBetList.length > 0 && (
                      <h5 className="mt-4">My Betting List (Coupon)</h5>
                    )}
                    {couponBetList.length > 0 && (
                      <Table striped bordered hover className="mt-2">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Coupon Type</th>
                            <th>Coupon Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {couponBetList.map((item: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item["couponType"]}</td>
                                <td>{item["couponValue"]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Tab>
                )}
                {(selectedGame.gameType === "Diamond" ||
                  selectedGame.gameType === "Diamond/Coupon") && (
                  <Tab eventKey="diamond" title="Bet Diamond">
                    <Row>
                      <Col>
                        <Form.Label>Coupon Type</Form.Label>
                      </Col>
                      <Col>
                        <Form.Label>Coupon Value</Form.Label>
                      </Col>
                      <Col>
                        <Form.Label>Diamond Amount</Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListGroup>
                          {couponTypeList.map((item: any) => (
                            <ListGroup.Item
                              key={uuidv4()}
                              onClick={() => {
                                setBetCouponType(item);
                                setBetCouponValue("");
                              }}
                              active={item === betCouponType}
                              style={{ cursor: "pointer" }}
                            >
                              {item}
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                      <Col>
                        <Form.Select
                          aria-label="Coupon"
                          value={betCouponValue}
                          onChange={(e) => {
                            setBetCouponValue(e.target.value);
                          }}
                        >
                          <option value="">Please select option</option>
                          {couponValueList.map((item: any) => (
                            <option key={uuidv4()}>{item}</option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          value={betDiamondAmount}
                          onChange={(e) => setBetDiamondAmount(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="m-2 mt-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button size="sm" onClick={betDiamondHandler}>
                        Bet Diamond
                      </Button>
                    </Row>

                    {diamondBetList.length > 0 && (
                      <h5 className="mt-4">My Betting List (Diamond)</h5>
                    )}
                    {diamondBetList.length > 0 && (
                      <Table striped bordered hover className="mt-2">
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Coupon Type</th>
                            <th>Coupon Value</th>
                            <th>Diamond Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {diamondBetList.map((item: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item["couponType"]}</td>
                                <td>{item["couponValue"]}</td>
                                <td>{item["diamondAmount"]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Tab>
                )}
              </Tabs>
            </Form>
          </Container>
        </div>
      </div>
    </Layout>
  );
}
