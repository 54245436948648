import React, { useState, useEffect, useMemo } from "react";
import { Container, Stack, Button, Modal, Table, Alert } from "react-bootstrap";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

import { store } from "../store";
import DiamondImg from "../assets/img/diamond.png";
import CouponImg from "../assets/img/coin.png";

import {
  getMyPredictionCouponListApi,
  getMyPredictionDiamondListApi,
  getGamesApi,
  setClaimCouponApi,
  setClaimDiamondApi,
} from "../apis/coupon";
import {
  customToastStyle,
  customToastSuccessStyle,
} from "../configs/constants";

export default function PredictionTable() {
  const [account] = store.useState("account");
  const [userInfo, setUserInfo] = store.useState("userInfo");

  const [tableData, setTableData]: [tableData: any, setTableData: Function] =
    useState([]);
  const [show, setShow] = useState(false)
  const [selectedGameIds, setSelectedGameIds] = useState([])
  const [selectedCouponType, setSelectedCouponType] = useState("")
  const [selectedCouponValue, setSelectedCouponValue] = useState("")
  const [games, setGames] = useState([])

  const parlayList = useMemo(() => {
    const filteredGames = games?.filter(
      (item: any) =>
        selectedGameIds.findIndex((_: any) => item.id === _) !== -1
    )
    let result = []
    for (const game of filteredGames) {
      let _game: any = { ...(game as any) };
      try {
        _game.coupons = JSON.parse(_game.coupons);
        for (let i = 0; i < _game.coupons.length; i++) {
          if (
            _game.coupons[i].couponType === selectedCouponType &&
            _game.coupons[i].couponValue === selectedCouponValue
          ) {
            _game.status = "Wined"
          }
        }
      } catch (e) { }

      result.push(_game)
    }
    return result
  }, [selectedGameIds, games, selectedCouponType, selectedCouponValue])

  const parlayInfoText = (num: number) => {
    let str = ""
    let score = 1
    for (let i = 0; i < num; i++) {
      str += `${i + 1} * `
      score = score * (i + 1);
    }
    str = str.substr(0, str.length - 2);
    if (num >= 4) {
      str += " + 10% Bonus";
      score = Math.floor(score * 1.1 * 100) / 100;
    }
    return `${str} = ${score}(X)`
  }

  const parlayScore = (num: number) => {
    let score = 1
    for (let i = 0; i < num; i++) {
      score = score * (i + 1);
    }
    if (num >= 4) {
      score = Math.floor(score * 1.1 * 100) / 100;
    }
    return score
  }

  const init = async () => {
    const couponList = await getMyPredictionCouponListApi(account);
    const diamondList = await getMyPredictionDiamondListApi(account);

    couponList.sort((a: any, b: any) => new Date(a?.createdAt) < new Date(b?.createdAt) ? 1 : -1)
    diamondList.sort((a: any, b: any) => new Date(a?.createdAt) < new Date(b?.createdAt) ? 1 : -1)

    const games = await getGamesApi();
    setGames(games);

    const couponData: any = couponList.map((coupon: any, index: number) => {
      const game = games.find((game: any) => game.id === coupon.gameId);

      let coupons = [];
      try {
        coupons = JSON.parse(game?.coupons);
      } catch (e) { }

      let isWinning = "";

      for (let i = 0; i < coupons.length; i++) {
        if (
          coupons[i].couponType === coupon.couponType &&
          coupons[i].couponValue === coupon?.couponValue
        ) {
          isWinning = "winner";
        }
      }

      let claimed = false;
      const claimCoupons = (userInfo as any).claimCoupons || [];
      if (claimCoupons.includes(coupon?.id)) {
        claimed = true;
      }

      return {
        ...coupon,
        type: "Coupon",
        sports: game?.sports,
        home: game?.home,
        away: game?.away,
        coupons: coupons,
        isWinning,
        claimed,
      };
    });
    const diamondData: any = diamondList.map((diamond: any, index: number) => {
      let gameIds: any = [];
      try {
        gameIds = JSON.parse(diamond.gameId)
      } catch (e) {
      }
      const isParlay = gameIds.length > 0 ? true : false;

      const game = games.find((game: any) => isParlay ? game.id === gameIds[0] : game.id === diamond.gameId);

      let isWinning = "";
      let coupons = [];

      if (!isParlay) {
        try {
          coupons = JSON.parse(game?.coupons);
        } catch (e) {
          coupons = []
        }


        for (let i = 0; i < coupons.length; i++) {
          if (
            coupons[i].couponType === diamond.couponType &&
            coupons[i].couponValue === diamond?.couponValue
          ) {
            isWinning = "winner";
          }
        }
      } else {
        const filteredGames = games?.filter(
          (item: any) =>
            gameIds.findIndex((_: any) => item.id === _) !== -1
        )
        let parlayWinCouponCount = 0;

        for (const game of filteredGames) {
          try {
            coupons = JSON.parse(game?.coupons);
          } catch (e) {
            coupons = []
          }


          for (let i = 0; i < coupons.length; i++) {
            if (
              coupons[i].couponType === diamond.couponType &&
              coupons[i].couponValue === diamond?.couponValue
            ) {
              parlayWinCouponCount++;
            }
          }
          if (parlayWinCouponCount === gameIds.length) {
            isWinning = "winner";
          }
        }
      }


      let claimed = false;
      const claimDiamonds = (userInfo as any).claimDiamonds || [];
      if (claimDiamonds.includes(diamond?.id)) {
        claimed = true;
      }

      return {
        ...diamond,
        type: "Diamond",
        sports: game?.sports,
        home: isParlay ? <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            setSelectedGameIds(gameIds);
            setSelectedCouponType(diamond.couponType);
            setSelectedCouponValue(diamond.couponValue);
            setShow(true);
          }}>
          {`Parlay(${gameIds.length})`}
        </div> : game?.home,
        away: isParlay ? <div style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            setSelectedGameIds(gameIds);
            setSelectedCouponType(diamond.couponType);
            setSelectedCouponValue(diamond.couponValue);
            setShow(true);
          }}>{`Parlay(${gameIds.length})`}</div> : game?.away,
        coupons: coupons,
        isWinning,
        claimed,
        isParlay,
        gameIds,
      };
    });

    const data = [...diamondData, ...couponData];

    for (let i = 0; i < data.length; i++) {
      data[i]["index"] = i;
    }

    setTableData(data);
  };

  useEffect(() => {
    init();
  }, [account, userInfo]);

  const claimHandler = async (row: any) => {
    let res = false;
    if (row.type === "Coupon") {
      res = await setClaimCouponApi({
        wallet: account,
        betCouponId: row?.id,
      });
    } else {
      // Diamond
      let amount = row?.diamondAmount;
      if (row?.isParlay) {
        amount = Number(amount / Number(row?.gameIds?.length)) * parlayScore(row?.gameIds?.length)
      }
      res = await setClaimDiamondApi({
        wallet: account,
        betDiamondId: row?.id,
        amount: amount + "",
      });
    }

    if (res === true) {
      if (row.type === "Coupons") {
        const claimCoupons = [...(userInfo as any)?.claimCoupons];
        claimCoupons.push(row.id);
        setUserInfo({
          ...(userInfo as any),
          claimCoupons: claimCoupons,
        });
      } else {
        const claimDiamonds = [...(userInfo as any)?.claimDiamonds];
        claimDiamonds.push(row.id);
        setUserInfo({
          ...(userInfo as any),
          claimDiamonds: claimDiamonds,
        });
      }
      toast("Get reward successfully", customToastSuccessStyle);
    } else {
      toast("Error to get reward", customToastStyle);
    }
  };

  const columns = [
    {
      name: "No",
      selector: (row: any) => row.index + 1,
      sortable: true,
    },
    {
      name: "Prediction Type",
      selector: (row: any) =>
        row.type === "Diamond" ? (
          <div>
            <img src={DiamondImg} alt="diamond" style={{ width: 20 }} /> Diamond
          </div>
        ) : (
          <div>
            <img src={CouponImg} alt="coupon" style={{ width: 25 }} /> Coupon
          </div>
        ),
      sortable: true,
    },
    {
      name: "Sports",
      selector: (row: any) => row.sports,
      sortable: true,
    },
    {
      name: "Home",
      selector: (row: any) => row.home,
      sortable: true,
    },
    {
      name: "Away",
      selector: (row: any) => row.away,
      sortable: true,
    },
    {
      name: "Coupon Type",
      selector: (row: any) => row.couponType,
      sortable: true,
    },
    {
      name: "Coupon Value",
      selector: (row: any) => row.couponValue,
      sortable: true,
    },
    {
      name: "Diamond Amount",
      selector: (row: any) => (row?.isParlay ? `${Number(row.diamondAmount) / Number(row?.gameIds?.length)} * ${row?.gameIds?.length}` : row.diamondAmount) || "",
      sortable: true,
    },
    {
      name: "Claim",
      selector: (row: any) =>
        row.isWinning === "winner" ? (
          row.claimed ? (
            <Button size="sm" variant="default" disabled>
              Claimed
            </Button>
          ) : (
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                claimHandler(row);
              }}
            >
              Claim Reward
            </Button>
          )
        ) : (
          ""
        ),
      sortable: true,
    },
  ];

  return (
    <Container>
      <Modal show={show} centered onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 18, fontWeight: "bold" }}>
            Parlay Bet Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="info">
            Estimated Calculation Score = {parlayInfoText(parlayList.length)}
          </Alert>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Sports</th>
                <th>Home Team</th>
                <th>Away Team</th>
                <th>Start Date</th>
                <th>Game Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                parlayList.map((item: any, index: number) => {
                  return (
                    <tr key={uuidv4()}>
                      <td>{index + 1}</td>
                      <td>{item?.sports === "Football" ? "Futbol" : item?.sports}</td>
                      <td>{item?.home}</td>
                      <td>{item?.away}</td>
                      <td>{item.startDate + " " + item.startTime}</td>
                      <td>{item?.gameType}</td>
                      <td style={{ color: item?.status === "Wined" ? "green" : "" }}>{item?.status}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => { setShow(false) }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Stack
        direction="horizontal"
        className="w-100 justify-content-md-between mt-5"
      >
        <h1>My Predictions</h1>
      </Stack>
      <Stack className="mt-4">
        <DataTable columns={columns} data={tableData} pagination />
      </Stack>
    </Container>
  );
}
