import { useState, useEffect, useMemo } from "react";
import {
    Container,
    Form,
    Row,
    Col,
    ListGroup,
    Button,
    Stack,
    Table,
    Tabs,
    Tab,
    Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import Layout from "../layout";
import Header from "../components/Header";
import { customToastStyle } from "../configs/constants";

import {
    betParlayGameDiamondApi,
    getBetParlayListDiamondApi
} from "../apis/coupon";

import { store } from "../store";

export default function BetParlayDiamond() {
    const nav = useNavigate();

    const { state: parlayList } = useLocation();
    const selectedGame = parlayList[0]
    const gameIds = parlayList.map((item: never) => (item as any)?.id)

    const [key, setKey]: [key: any, setKey: Function] = useState("diamond");

    const [betCouponType, setBetCouponType] = useState("");
    const [myBetCouponType, setMyBetCouponType] = useState("");
    const [betCouponValue, setBetCouponValue] = useState("");
    const [myBetCouponValue, setMyBetCouponValue] = useState("");
    const [betDiamondAmount, setBetDiamondAmount] = useState("");
    const [diamondBetList, setDiamondBetList]: [
        diamondBetList: any,
        setDiamondBetList: Function
    ] = useState([]);
    const [couponBetList, setCouponBetList]: [
        couponBetList: any,
        setCouponBetList: Function
    ] = useState([]);

    const [coupons] = store.useState("coupons");
    const [account] = store.useState("account");
    const [userInfo, setUserInfo] = store.useState("userInfo");
    const diamondBalance = (userInfo as any)?.diamond;
    const myCouponIds: any = (userInfo as any)?.coupons || {};

    const filteredCoupons = (coupons as any)?.filter(
        (item: any) => item?.Sport === selectedGame?.sports
    );

    const myCoupons = useMemo(() => {
        const couponIds = Object.keys(myCouponIds) || [];
        const result = [];
        let resultIndex = 0;
        for (const couponId of couponIds) {
            const index = (coupons as any).findIndex(
                (coupon: any) => coupon.id === couponId
            );
            const _coupon = (coupons as any)[index];
            result.push({
                ..._coupon,
                hold: myCouponIds[couponId],
                index: resultIndex,
            });
            resultIndex++;
        }
        return result;
    }, [userInfo, coupons, myCouponIds]);

    const filteredMyCoupons = (myCoupons as any)?.filter(
        (item: any) => item?.Sport === selectedGame?.sports
    );

    const couponTypeList = useMemo(() => {
        const data: any = {};
        for (const coupon of filteredCoupons) {
            if (data[coupon["Coupon Type"]] === undefined) {
                data[coupon["Coupon Type"]] = "";
            }
        }
        return Object.keys(data);
    }, [filteredCoupons]);

    const myCouponTypeList = useMemo(() => {
        const data: any = {};
        for (const coupon of filteredMyCoupons) {
            if (data[coupon["Coupon Type"]] === undefined) {
                data[coupon["Coupon Type"]] = "";
            }
        }
        return Object.keys(data);
    }, [filteredMyCoupons]);

    const couponValueList = useMemo(() => {
        try {
            if (betCouponType === "") {
                return [];
            }
            const filteredByType = filteredCoupons?.filter((item: any) => {
                return item["Coupon Type"] === betCouponType;
            });

            const data: any = {};
            for (const coupon of filteredByType) {
                if (data[coupon["Value"]] === undefined) {
                    data[coupon["Value"]] = "";
                }
            }
            return Object.keys(data);
        } catch (e) {
            return [];
        }
    }, [filteredCoupons, couponTypeList]);

    const myCouponValueList = useMemo(() => {
        try {
            if (myBetCouponType === "") {
                return [];
            }
            const myFilteredByType = filteredMyCoupons?.filter((item: any) => {
                return item["Coupon Type"] === myBetCouponType;
            });

            const data: any = {};
            for (const coupon of myFilteredByType) {
                if (data[coupon["Value"]] === undefined) {
                    data[coupon["Value"]] = "";
                }
            }
            return Object.keys(data);
        } catch (e) {
            return [];
        }
    }, [filteredMyCoupons]);

    useEffect(() => {
        (async () => {
            setDiamondBetList(
                await getBetParlayListDiamondApi({
                    gameId: JSON.stringify(gameIds),
                    wallet: account,
                })
            );
        })();
    }, []);

    const parlayInfoText = (num: number) => {
        let str = ""
        let score = 1
        for (let i = 0; i < num; i++) {
            str += `${i + 1} * `
            score = score * (i + 1);
        }
        str = str.substr(0, str.length - 2);
        if (num >= 4) {
            str += " + 10% Bonus";
            score = Math.floor(score * 1.1 * 100) / 100;
        }
        return `${str} = ${score}(X)`
    }

    const betDiamondHandler = async () => {
        if (betCouponType === "") {
            return toast("Select a coupon type", customToastStyle);
        }
        if (betCouponValue === "") {
            return toast("Select a coupon value", customToastStyle);
        }
        if (isNaN(parseFloat(betDiamondAmount))) {
            return toast("Please input diamond amount", customToastStyle);
        }
        if (parseFloat(diamondBalance) < parseFloat(betDiamondAmount) * parlayList.length) {
            return toast("Diamond balance error", customToastStyle);
        }

        const selectedCoupon = (coupons as any)?.filter(
            (item: any) =>
                item?.Sport === selectedGame?.sports &&
                item["Coupon Type"] === betCouponType &&
                item["Value"] === betCouponValue
        );

        const res = await betParlayGameDiamondApi({
            gameId: JSON.stringify(gameIds),
            wallet: account,
            couponType: betCouponType,
            couponValue: betCouponValue,
            diamondAmount: (Number(betDiamondAmount) * parlayList.length) + "",
            couponId: selectedCoupon[0].id,
        });

        if (res === true) {
            toast("Bet game with diamond successfully", customToastStyle);
            setUserInfo({
                ...(userInfo as any),
                diamond:
                    parseFloat((userInfo as any).diamond) - parseFloat(betDiamondAmount) * parlayList.length,
            });
        } else {
            return toast("Betting error", customToastStyle);
        }

        setDiamondBetList([
            ...diamondBetList,
            {
                couponType: betCouponType,
                couponValue: betCouponValue,
                diamondAmount: betDiamondAmount,
            },
        ]);

        setBetCouponType("");
        setBetCouponValue("");
        setBetDiamondAmount("");
    };

    return (
        <Layout>
            <div className="page-container">
                <div style={{ zIndex: 2 }} className="w-100">
                    <Header />
                    <Container style={{ marginTop: 50 }}>
                        <Form className="w-100">
                            <Stack
                                direction="horizontal"
                                style={{ alignItems: "center", display: "flex" }}
                            >
                                <h2>Parlay Bet Diamond</h2>
                            </Stack>

                            <Tabs
                                activeKey={key}
                                onSelect={(k) => setKey(k)}
                                className="mb-3"
                            >
                                <Tab eventKey="diamond" title="Bet Diamond">
                                    <Alert variant="info">
                                        Estimated Calculation Score = {parlayInfoText(parlayList.length)}
                                    </Alert>
                                    <Row>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Sports</th>
                                                    <th>Home Team</th>
                                                    <th>Away Team</th>
                                                    <th>Start Date</th>
                                                    <th>Game Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    parlayList.map((item: any, index: number) => {
                                                        return (
                                                            <tr key={uuidv4()}>
                                                                <td>{index + 1}</td>
                                                                <td>{item?.sports === "Football" ? "Futbol" : item?.sports}</td>
                                                                <td>{item?.home}</td>
                                                                <td>{item?.away}</td>
                                                                <td>{item.startDate + " " + item.startTime}</td>
                                                                <td>{item?.gameType}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label>Coupon Type</Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Label>Coupon Value</Form.Label>
                                        </Col>
                                        <Col>
                                            <Form.Label>Diamond Amount</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ListGroup>
                                                {couponTypeList.map((item: any) => (
                                                    <ListGroup.Item
                                                        key={uuidv4()}
                                                        onClick={() => {
                                                            setBetCouponType(item);
                                                            setBetCouponValue("");
                                                        }}
                                                        active={item === betCouponType}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {item}
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </Col>
                                        <Col>
                                            <Form.Select
                                                aria-label="Coupon"
                                                value={betCouponValue}
                                                onChange={(e) => {
                                                    setBetCouponValue(e.target.value);
                                                }}
                                            >
                                                <option value="">Please select option</option>
                                                {couponValueList.map((item: any) => (
                                                    <option key={uuidv4()}>{item}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                value={betDiamondAmount}
                                                onChange={(e) => setBetDiamondAmount(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        className="m-2 mt-4"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button size="sm" onClick={betDiamondHandler}>
                                            Bet Diamond
                                        </Button>
                                    </Row>

                                    {diamondBetList.length > 0 && (
                                        <h5 className="mt-4">My Betting List (Diamond)</h5>
                                    )}
                                    {diamondBetList.length > 0 && (
                                        <Table striped bordered hover className="mt-2">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Coupon Type</th>
                                                    <th>Coupon Value</th>
                                                    <th>Diamond Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {diamondBetList.map((item: any, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item["couponType"]}</td>
                                                            <td>{item["couponValue"]}</td>
                                                            <td>{item["diamondAmount"]}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                    )}
                                </Tab>
                            </Tabs>
                        </Form>
                    </Container>
                </div>
            </div>
        </Layout>
    );
}
